/* General Styles */
body {
  margin: 0;
  font-family: "Roboto", Arial, sans-serif;
  background-color: black;
  color: #e0e0e0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Dashboard Container */
.dashboard-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  border-radius: 10px;
}

h1 {
  font-size: 2.2rem;
  margin-bottom: 20px;
  text-align: center;
  color: #00ff00;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
}

/* Subheading (Email) */
.dashboard-container p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #bdbdbd;
}

/* Stocks Selection Section */
.stocks-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-bottom: 30px;
}

.bp5-button {
  font-size: 1rem;
  padding: 10px 20px;
  width: auto;
}

.bp5-button:hover {
  transform: scale(1.03);
}

/* Selected Stocks List */
.selected-stocks-list {
  margin-top: 20px;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: #1e1e1e;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

/* Grid Layout for Items */
.selected-stocks-list ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.selected-stocks-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  border: 1px solid #555;
  color: #fff;
  padding: 15px 10px;
  border-radius: 8px;
  font-size: 1rem;
  transition: transform 0.2s ease-in-out, background-color 0.3s ease;
}

.selected-stocks-list li:hover {
  transform: translateY(-3px);
  background-color: #444;
}

/* Delete Button */
.delete-button {
  background: none;
  border: none;
  color: red;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
}

.delete-button:hover {
  color: darkred;
}

.bp5-button {
  border-radius: 10px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .dashboard-container {
    padding: 15px;
  }

  h1 {
    font-size: 2rem;
  }

  .stocks-selection {
    width: 90%;
  }

  .selected-stocks-list ul {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust grid for medium screens */
  }

  .bp5-button {
    font-size: 0.9rem;
    padding: 8px 15px;
  }
}

@media (max-width: 768px) {
  .dashboard-container {
    padding: 10px;
  }

  h1 {
    font-size: 1.8rem;
  }

  .stocks-selection {
    width: 100%;
  }

  .selected-stocks-list ul {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Fewer columns on smaller screens */
  }

  .selected-stocks-list li {
    font-size: 0.9rem;
    padding: 10px;
  }

  .bp5-button {
    font-size: 0.8rem;
    padding: 6px 10px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }

  .selected-stocks-list ul {
    grid-template-columns: 1fr; /* Single column on very small screens */
  }

  .bp5-button {
    font-size: 0.9rem;
    padding: 8px 15px;
  }
}

.red {
  color: #ff5555;
}

@media (max-width: 820px) {
  .user-menu {
      top: 83px;
      right: 35px;
  }

  .my-watchlist {
    padding: 5px 10px !important;
    font-size: inherit !important;
  }
}

.my-watchlist {
  display: flex;
  background-color: #333; /* Dark gray background */
  color: #fff; /* White text */
  text-decoration: none; /* Remove underline */
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 8px; /* Rounded corners */
  display: inline-flex; /* Center content */
  gap: 4px;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Pointer cursor for interactivity */
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-decoration: none;
}

.my-watchlist:hover {
  color: #ff5555;
}

.select-form {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.red-cross {
  color: red;
  border: none;
  background: transparent;
  font-size: 16px;
  cursor: pointer;
}

.red-cross:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.updating-spinner {
  margin: 20px auto;
  display: block;
}

.no-selected-stocks {
  text-align: center;
  /* background-color: #222; */
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px !important;
  font-size: 14px;
}

.fullscreen-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #000; /* Dark background for better visibility */
  color: #ffffff; /* Text color for readability on dark background */
}

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px; /* Adds spacing between the spinner and the message */
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #00ff00;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-container p {
  font-size: 1.2rem; /* Slightly larger font for better readability */
  font-weight: 500;
  margin: 0;
  text-align: center;
}

.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #ff5555;
}

.limit-message {
  background-color: #1e1e1e; /* Light red background for warning */
  color: #4caf50; /* Dark red text color */
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
  text-align: center;
}

.limit-message p {
  margin: 0;
  padding-bottom: 10px;
}

.limit-message button {
  padding: 8px 16px;
  background-color: #4caf50; /* Soft green background for the button */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff; /* Dark green text for the button */
  font-weight: bold;
}

.limit-message button:hover {
  background-color: #c3e6cb; /* Slightly lighter green on hover */
}
