.bubble-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 120px); /* Adjust height based on header and footer */
}

.no-stocks-message {
  text-align: center;
  font-size: 1.5rem;
  color: #333;
}

.no-stocks-message p {
  margin-bottom: 15px;
  color: white;
}

.dashboard-link {
  display: inline-block;
  padding: 5px 10px;
  background-color: #00ff00;
  color: #000;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}

.dashboard-link:hover {
  background-color: #00cc00;
}
