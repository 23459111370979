.user-profile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85); /* Darker overlay for emphasis */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.user-profile-popup {
  background: linear-gradient(145deg, #ffffff, #f3f3f3); /* Soft gradient */
  border-radius: 20px; /* Rounded corners for a smoother look */
  padding: 25px;
  width: 320px;
  text-align: center;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4); /* Deeper shadow for depth */
  animation: scaleIn 0.3s ease; /* Subtle scaling effect */
}

@keyframes scaleIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #555;
  cursor: pointer;
  transition: color 0.3s;
}

.close-button:hover {
  color: #ff4d4d; /* Subtle hover effect */
}

.user-profile-avatar {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  box-shadow: 0 4px 15px #ff5555; /* Soft glow effect */
}

.user-profile-name {
  font-size: 26px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #222;
  letter-spacing: 0.5px;
}

.user-profile-email {
  font-size: 14px;
  color: #555;
  margin-bottom: 25px;
  font-style: italic; /* Subtle styling */
}

.user-profile-links {
  display: flex;
  flex-direction: column;
}

.user-profile-links button {
  margin: 5px 0;
}

.profile-link {
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  color: #333;
  padding: 10px;
  border-radius: 10px;
  transition: all 0.3s;
  background: rgba(0, 123, 255, 0.1); /* Subtle background for links */
}

.profile-link:hover {
  background: rgba(0, 123, 255, 0.3); /* Highlight on hover */
  color: #007bff;
}

.settings-button {
  display: flex;
  align-items: center;
  gap: 12px;
  text-decoration: none;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  transition: all 0.3s;
  background: rgba(0, 123, 255, 0.1); /* Subtle background for links */
}

.settings-button:hover {
  background: rgba(0, 123, 255, 0.3); /* Highlight on hover */
  color: #007bff;
}

.profile-icon {
  font-size: 20px;
}

.logout-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #ff4d4d; /* Vibrant red button */
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 12px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
  font-weight: bold;
}

.logout-button:hover {
  background: #ff5555; /* Slightly darker red on hover */
  transform: scale(1.05); /* Subtle scaling effect */
}

.logout-button:active {
  transform: scale(0.95); /* Active click effect */
}

.portfolios-button {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #333;
  border: none;
  border-radius: 8px;
  border: 2px solid black;
  padding: 12px 15px;
  background-color: rgba(0, 123, 255, 0.1);
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.portfolios-button:hover {
  background: rgba(0, 123, 255, 0.3); /* Highlight on hover */
  color: #007bff;
}

.portfolios-button:active {
  transform: scale(0.95); /* Active click effect */
}
