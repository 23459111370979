/* General styles for forms and select elements */
.portfolio-form {
  padding: 10px;
  width: 100%;
  background-color: #1e1e1e; /* Light mode background */
  border-radius: 8px;
  color: #4caf50; /* Text color for light mode */
}

.dark-mode .portfolio-form {
  background-color: #2c2c2c; /* Dark mode background */
  color: #1e1e1e; /* Text color for dark mode */
}

/* Label styling */
.portfolio-form label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: inherit; /* Inherits from .portfolio-form for flexibility */
}

/* Select input styling */
.select-portfolio {
  width: 100%;
  padding: 8px 10px;
  margin-bottom: 12px;
  background-color: #1e1e1e; /* Light mode input background */
  border: 1px solid #1e1e1e;
  border-radius: 4px;
  font-size: 14px;
  color: #4caf50; /* Text color for inputs */
}

.dark-mode .select-portfolio {
  background-color: #424242; /* Dark mode input background */
  border: 1px solid #666666;
  color: #1e1e1e; /* Text color for inputs in dark mode */
}

.select-portfolio:disabled {
  background-color: #e9ecef; /* Lighter grey when disabled */
}

.dark-mode .select-portfolio:disabled {
  background-color: #3a3a3a; /* Darker grey for disabled state in dark mode */
}

/* Button styling */
.portfolio-form button {
  padding: 10px 20px;
  background-color: #4CAF50; /* Green background */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.portfolio-form button:disabled {
  background-color: #1e1e1e; /* Greyed out when disabled */
}

.portfolio-form button:hover:enabled {
  background-color: #367c39; /* Darker green on hover */
}

/* Dashboard content styling for responsive centering */
.dashboard-content {
  width: 50%;
  margin: 0 auto;
}

.empty-message {
  text-align: left;
}

.invoice-btn {
  background-color: #ff5555; /* Adjust the color to your preference */
  color: #fff;
  padding: 3px 8px; /* Provides padding inside the button */
  border: none; /* Removes the default border */
  border-radius: 4px; /* Rounds the corners of the button */
  font-size: 16px; /* Sets a suitable font size */
  cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
  text-align: center; /* Ensures text is centered */
  text-decoration: none; /* Removes underline from links styled as buttons */
  display: inline-block; /* Ensures it’s treated as a button not a block element */
}

.invoice-btn:hover {
  background-color: #ff5555; /* Darker Green */
  transform: scale(1.05);
}