/* Root Variables for Theme */
:root {
  --navbar-bg: #000;
  --navbar-link-color: #ffffff;
  --navbar-link-hover: #00ff00;
  --footer-bg: #000;
  --footer-link-color: #00ff00;
  --footer-link-hover: #ffffff;
  --button-bg: #1e1e1e;
  --button-hover-bg: #2b2b2b;
  --button-color: #ffffff;
  --button-disabled-bg: #444;
  --button-disabled-color: #777;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
}

.logo .green {
  color: #00ff00;
}

.logo .red {
  color: #ff5555;
}

/* Body Styling */
body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  /* overflow: hidden; */
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

button:focus, input:focus, a:focus {
  outline: none;
  box-shadow: none;
}

.logout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #000;
  color: #333;
  font-family: Arial, sans-serif;
  animation: fadeIn 0.5s ease-in-out;
}

.spinner {
  margin-top: 20px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #00ff00;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.logout {
  color: white;
}

.logo:hover {
  cursor: pointer;
  text-decoration: none;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #ff5555;
}
